// src/components/Fw9.js

import React from 'react';
import { PDFDocument } from 'pdf-lib';
import fw9Pdf from './fw9.pdf';          // Import the W-9 PDF from the components folder
import signatureImage from './signature.png'; // Import the signature image
import { useLocation } from 'react-router-dom';
import { Helmet } from "react-helmet";

import styles from "./Fw9.module.css";

export const Fw9 = () => {
    const location = useLocation();

    // Function to get the query parameter value
    const getQueryParam = (param) => {
        const searchParams = new URLSearchParams(location.search);
        return searchParams.get(param);
    };

    // Access the 'sign' parameter
    let sign = getQueryParam('sign');
    if (sign === null) {
        sign = false;
    } else {
        sign = sign === '1';
    }
    console.log('sign', sign);

    const formatDate = (date) => {
        let month = date.getMonth() + 1;  // Months are zero-indexed, so we add 1
        if (month < 10) {
            month = '0'+month;
        }
        const day = date.getDate();
        const year = date.getFullYear();
      
        return `${month}/${day}/${year}`;
    };
    
    const generatePdf = async (sign) => {
        // Fetch the PDF from the imported file path
        const existingPdfBytes = await fetch(fw9Pdf).then((res) => res.arrayBuffer());

        // Load the existing PDF
        const pdfDoc = await PDFDocument.load(existingPdfBytes);
        const pages = pdfDoc.getPages();
        const firstPage = pages[0];

        // Add text to the PDF fields (adjust coordinates as per form layout)
        firstPage.drawText('Quotible, Inc.', { x: 120, y: 665, size: 12 });
        firstPage.drawText('Quotible', { x: 120, y: 640, size: 12 });
        firstPage.drawText('2065 E. Windmill Ln. #144', { x: 120, y: 495, size: 12 });
        firstPage.drawText('Las Vegas, NV 89123', { x: 120, y: 472, size: 12 });
        // C-Corporation X
        firstPage.drawText('x', { x: 181, y: 605, size: 12 });
        // employee ID number
        firstPage.drawText('8', { x: 421, y: 357, size: 12 });
        firstPage.drawText('5', { x: 436, y: 357, size: 12 });
        firstPage.drawText('2', { x: 465, y: 357, size: 12 });
        firstPage.drawText('1', { x: 479, y: 357, size: 12 });
        firstPage.drawText('9', { x: 494, y: 357, size: 12 });
        firstPage.drawText('5', { x: 509, y: 357, size: 12 });
        firstPage.drawText('6', { x: 523, y: 357, size: 12 });
        firstPage.drawText('6', { x: 537, y: 357, size: 12 });
        firstPage.drawText('6', { x: 551, y: 357, size: 12 });

        if (sign) {
            // Embed the signature image
            const signatureBytes = await fetch(signatureImage).then((res) => res.arrayBuffer());
            const signatureImg = await pdfDoc.embedPng(signatureBytes);
            firstPage.drawImage(signatureImg, { x: 130, y: 195, width: 170, height: 20 });
        }

        // Add the current date next to the signature
        firstPage.drawText(formatDate(new Date()), { x: 425, y: 198, size: 12 });

        // Serialize the PDFDocument to bytes (Uint8Array)
        const pdfBytes = await pdfDoc.save();

        // Create a Blob from the PDF bytes
        const blob = new Blob([pdfBytes], { type: 'application/pdf' });
        const url = URL.createObjectURL(blob);

        // Create a link element, set its href to the Blob URL, and click it to trigger download
        const link = document.createElement('a');
        link.href = url;
        link.download = 'filled-w9.pdf';
        link.click();

        // Cleanup the URL Object
        URL.revokeObjectURL(url);
    };

    return (
        <div className={styles.container}>
            <Helmet>
                <title>Fw9 Download</title>
            </Helmet>
            <h1 className={styles.title}>Generate W-9 PDF</h1>
            <button className={styles.button} onClick={() => generatePdf(sign)}>
                Download PDF
            </button>
        </div>
    );
};
